import React, { useState } from 'react'
import {MathfieldComponent} from 'react-mathlive'
function App() {
  const [latex, setLatex] = React.useState("f(x)=\\log _10 x");
  const [neq, setNeq] = React.useState("f(x)=\\log _10 x");
  return (
    <div style={{ position: "absolute", top: "50%", left: "50%" }}>
      <MathfieldComponent
        latex={latex}
        onChange={setLatex}
        mathfieldConfig={{
          virtualKeyboardMode: "onfocus"
        }}
      />
    </div>
  )
}

export default App
